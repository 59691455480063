import { Chip, Grid, Typography, Box } from '@mui/material';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Page from 'components/Page';
import subscriptionPlans from 'constants/services/subscription-plans';
import user from 'constants/services/user';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { showError } from 'utils/toast';
import TopMenu from '../../components/TopMenu';
import './style.css';
import { Fragment } from './styled-components';
import { SubscribeButton } from './SubscribeButton';

export default function Payment() {
    const { t } = useTranslation();
    const location = useLocation();

    const containerClass = location?.pathname === '/payment' ? 'mdCustomPadding' : '';

    const [isSubscribedUser, setIsSubscribedUser] = useState(false);
    const [subValidUntil, setSubValidUntil] = useState(moment().format('MMM DD, YYYY'));
    const [subscriptionOptions, setSubscriptionOptions] = useState([]);
    const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);

    const validateSubscription = async () => {
        try {
            const response = await user.validateSubscription();
            const { isSubscribed, validUntil } = response;
            setSubValidUntil(validUntil);
            setIsSubscribedUser(isSubscribed);

            let subStartDate = moment.utc().startOf('day');
            if (isSubscribed) {
                subStartDate = moment.utc(validUntil).add(1, 'day');
                setSubscriptionStartDate(subStartDate);
            }

            const subscriptionPlansResponse = await subscriptionPlans.getSubscriptionPlans();
            setSubscriptionOptions(subscriptionPlansResponse?.data || []);
        } catch (error) {
            showError(t, error);
        }
    };

    useEffect(() => {
        validateSubscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Page title={t('payment-info.title')}>
                <div className={containerClass}>
                    <TopMenu title={t('payment-info.title')} hideHelp />
                    <div className="contentStyle" style={{ paddingTop: 0 }}>
                        <Typography variant="h4" className="commonHeading" style={{ paddingBottom: '22px' }}>
                            {isSubscribedUser
                                ? t('payment-info.payment-message-not-expired', {
                                    subExpiryDate: moment(subValidUntil).format('MMM DD, YYYY'),
                                })
                                : t('payment-info.payment-message-sub-expired')}
                        </Typography>
                        <Grid container spacing={2}>
                            <PayPalScriptProvider
                                options={{
                                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                    components: 'buttons',
                                    intent: 'subscription',
                                    vault: true,
                                }}
                            >
                                {subscriptionOptions.map((option) => (
                                    <Grid item xl={3} lg={4} md={6} sm={12} key={option.id}>
                                        <div className="subscription-info-card">
                                            <div className="subscription-details">
                                                <Typography variant="body1">{option.name}</Typography>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Chip
                                                        sx={{
                                                            height: 'auto',
                                                            '& .MuiChip-label': {
                                                                display: 'block',
                                                                whiteSpace: 'normal',
                                                            },
                                                            padding: '1px',
                                                            fontWeight: '600',
                                                            width: 'fit-content',
                                                        }}
                                                        label={option.description}
                                                        color="info"
                                                    />
                                                    <Chip
                                                        sx={{
                                                            height: 'auto',
                                                            '& .MuiChip-label': {
                                                                display: 'block',
                                                                whiteSpace: 'normal',
                                                            },
                                                            padding: '1px',
                                                            fontWeight: '600',
                                                            width: 'fit-content',
                                                        }}
                                                        label={`Starts on ${
                                                            subscriptionStartDate
                                                                ? subscriptionStartDate.format('MMM DD, YYYY')
                                                                : moment().format('MMM DD, YYYY')
                                                        }`}
                                                        color="warning"
                                                    />
                                                </Box>
                                                <Typography variant="body" sx={{ paddingTop: 2 }}>
                                                    {t(`payment-info.${option.subscriptionType}`)}
                                                </Typography>
                                            </div>

                                            <SubscribeButton
                                                plan={option}
                                                subscriptionStartDate={
                                                    subscriptionStartDate ? subscriptionStartDate.toISOString() : ''
                                                }
                                            />
                                        </div>
                                    </Grid>
                                ))}
                            </PayPalScriptProvider>
                        </Grid>
                    </div>
                </div>
            </Page>
        </Fragment>
    );
}
