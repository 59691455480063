import React, { useRef } from 'react';
import { Typography } from '@mui/material'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const GetYourFinancial = () => {
    const slickSliderRef = useRef();

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 1600,
        autoplaySpeed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    };

    return (
        <>
            <div className="marqueeText hideMobileDiv">
                <Slider {...sliderSettings} ref={slickSliderRef} style={{ marginBottom: '10px', textAlign: 'center'}}>
                    <Typography variant='body'> Get your Finances on Track</Typography>
                    <Typography variant='body'> Sign-up for a 7-days Free Trial</Typography>
                    <Typography variant='body'> Get Access to Financial Calculators</Typography>
                    <Typography variant='body'> Get your Finances on Track</Typography>
                    <Typography variant='body'> Sign-up for a 7-days Free Trial</Typography>
                    <Typography variant='body'> Get Access to Financial Calculators</Typography>
                </Slider>
            </div>
        </>
    );
}

export default GetYourFinancial
